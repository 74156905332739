@media screen and (max-width: 1440px) {
  .home-option {
    font-size: 24px !important;
  }

  .menu-option-text {
    font-size: 18px !important;
  }

  .organization-flip-card {
    width: 270px;
    height: 270px;
  }

  .organization-card {
    width: 270px;
    height: 270px;
  }

  .organization-flip-card-logo {
    height: 200px;
    width: 200px;
  }

  .navigation-grid-title {
    font-size: 16px !important;
  }

  .register-data-item {
    text-transform: uppercase;
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 1024px) {
  .home-option {
    font-size: 24px !important;
  }

  .menu-option-text {
    font-size: 18px !important;
  }

  .organization-flip-card {
    width: 400px;
    height: 400px;
  }

  .organization-card {
    width: 400px;
    height: 400px;
  }

  .organization-flip-card-logo {
    height: 250px;
    width: 250px;
    filter: invert(100%);
  }
  .register-data-item {
    text-transform: uppercase;
    font-size: 1rem !important;
  }

  .navigation-grid-title {
    font-size: 12px !important;
  }

  .register-data-item {
    text-transform: uppercase;
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 420px) {
  .table-header {
    font-size: 12px;
  }
  .table-cell {
    font-size: 12px;
  }
  h4 {
    font-size: 24px !important;
  }

  h6 {
    font-size: 12px !important;
  }

  a {
    font-size: 12px !important;
  }

  p {
    font-size: 12px !important;
  }
}
