@font-face {
  font-family: "Inter-Medium";
  src: url("../public/fonts/static/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Light";
  src: url("../public/fonts/static/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("../public/fonts/static/Inter-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home-option {
  transition: transform 200ms;
  cursor: pointer;
  font-size: 36px !important;
  color: #fff;
  font-family: Inter-Bold !important;
  transition: 0.3s;
  z-index: 1;
}

.menu-option-text {
  transition: transform 200ms;
  cursor: pointer;
  font-size: 24px !important;
  color: #fff;
  font-family: Inter-Bold !important;
  transition: 0.3s;
  z-index: 1;
}

.home-link {
  text-decoration: none;
  z-index: 1;
}

.organizations-list-card {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
}

.organizations-list-card:focus {
  filter: invert(100%);
}

.organization-card {
  background-color: transparent;
  width: 400px;
  height: 400px;
  perspective: 1000px;
  border-radius: 10px;
}

.organization-flip-card {
  background-color: transparent;
  width: 400px;
  height: 400px;
  perspective: 1000px;
  border-radius: 10px;
}

.organization-flip-card-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  border-radius: 10px;
}

.organization-flip-card:focus {
  outline: 0;
}

.organization-flip-card-front,
.organization-flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.organization-flip-card-front {
  z-index: 2;
  display: flex;
}

.organization-flip-card-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.logo-hovered {
  filter: invert(100%);
}

.table-cell {
  font-size: 18px;
  transition: background-color 0.3s ease-in-out;
}

.table-header {
  font-size: 20px;
  font-weight: bold;
  font-family: "Inter-Medium";
  background-color: #000;
  color: #fff;
}

.sdg-flip-card {
  background-color: transparent;
  width: 250px;
  height: 250px;
  perspective: 1000px;
}

.sdg-flip-card-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.sdg-flip-card:focus {
  outline: 0;
}

.sdg-flip-card-front,
.sdg-flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
}

.sdg-flip-card-front {
  z-index: 2;
  display: flex;
}

.sdg-flip-card-back {
  background: #fff;
  color: #000;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.home-page-container {
  height: calc(100vh - 94px);
  background-color: #000;
}

.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  opacity: 0.5;
}

.home-page-option {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  cursor: pointer;
  border: 5px #000 solid;
}

.home-page-option:hover .image-overlay {
  opacity: 1;
  filter: none;
}

.menu-option {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  cursor: pointer;
  border: 2px #000 solid;
}

.menu-option:hover .image-overlay {
  opacity: 1;
  filter: none;
}

.navigation-grid {
  height: 50px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navigation-grid:hover {
  background-color: #000;
  color: #fff;
}

.navigation-grid-title {
  font-size: 1rem !important;
}

.register-data-item {
  text-transform: uppercase;
  font-size: 1.5rem !important;
}
