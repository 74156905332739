/*
=============== 
Variables
===============
*/

:root {
  --clr-black: #000;
  --clr-grey-1: #0a0a0a;
  --clr-grey-2: #1a1a1a;
  --clr-grey-3: #2b2b2b;
  --clr-grey-4: #666;
  --clr-grey-5: #929292;
  --clr-grey-6: #b3b3b3;
  --clr-grey-7: #dadada;
  --clr-grey-8: #efefef;
  --clr-white: #fff;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1920px;
  --regular-width: 1600px;
  --tight-width: 1000px;
  --pad-minimum: 15px;
  --pad-standard: 30px;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

/*
=============== 
Global Styles
===============
*/

.footer body {
  font-family: var(--ff-primary);
  background-color: var(--clr-white);
  color: var(--clr-grey-10);
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

.footer ul {
  list-style-type: none;
}
.footer a {
  text-decoration: none;
  color: var(--clr-grey-1);
}

.footer a:hover {
  color: var(--clr-primary-1);
  cursor: pointer;
}

.footer h4 {
  line-height: 1.2;
  font-family: var(--ff-primary);
  word-spacing: var(--spacing);
  color: var(--clr-black);
}

.footer h1 {
  font-size: 4.2rem;
  font-weight: 600;
  margin-bottom: 60px;
}
.footer .h1-subtitle {
  font-size: 1.8rem;
}

.footer h4 {
  font-size: 1.5rem;
}

.footer p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-4);
  font-size: 1rem;
}

.footer li {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-4);
  font-size: 1rem;
}

.container {
  width: 100%;
  max-width: var(--regular-width);
  margin: 0 auto;
  display: grid;
  align-self: center;
}

.partners-footer {
  width: 100%;
  text-align: center;
  padding-top: 60px;
  background-color: var(--clr-grey-8);
}
.partners-footer a {
  display: inline-block;
  margin: 0 20px 60px;
  vertical-align: middle;
}
.partners-footer a img {
  width: 100%;
  height: auto;
}

.partners-footer #ueu img {
  max-width: 120px;
}
.partners-footer #latu img {
  max-width: 80px;
}
.partners-footer #sistema-b img {
  max-width: 70px;
}
.partners-footer #deres img {
  max-width: 60px;
}
.partners-footer #uruguay img {
  max-width: 115px;
}
.partners-footer #lideco img {
  max-width: 110px;
}
.partners-footer #abpu img {
  max-width: 110px;
}
.partners-footer #pacto-global img {
  max-width: 145px;
}
.partners-footer #sincronia img {
  max-width: 140px;
}
.partners-footer #pyxis img {
  max-width: 130px;
}
.partners-footer #fibras img {
  max-width: 130px;
}

footer .logo-altus {
  margin: 120px auto 60px;
  display: grid;
  justify-items: center;
}

footer .logo-altus img {
  max-width: 300px;
  padding-left: 30px;
}

footer .logo-altus p {
  text-align: center;
  margin-top: 60px;
}

footer .info-footer {
  display: grid;
  grid-template-columns: 320px 1fr 320px;
  gap: 30px;
  padding: 30px 30px 60px;
}

footer .info-footer .lsqa-info {
  margin-bottom: 60px;
}

footer .info-footer .lsqa-info img {
  max-width: 150px;
  margin-bottom: 30px;
}

footer .info-footer .altus-info {
  text-align: center;
  margin-bottom: 60px;
}

footer .info-footer .altus-info .social-network {
  display: grid;
  grid-template-columns: min-content min-content min-content;
  justify-content: center;
  gap: 15px;
  margin-bottom: 15px;
}

footer .info-footer .altus-info .social-network li a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid var(--clr-black);
  align-content: center;
  display: grid;
  align-items: center;
  justify-items: center;
}

footer .info-footer .altus-info .social-network li a:hover {
  background-color: var(--clr-black);
}

footer .info-footer .altus-info .social-network li a img {
  width: 25px;
}

footer .info-footer .altus-info .social-network li a:hover img {
  filter: invert(100%);
}

footer .info-footer .sitemap {
  display: grid;
  justify-content: end;
}

footer .info-footer .sitemap h4 {
  font-weight: 400;
  margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
  footer .info-footer {
    grid-template-columns: 1fr;
  }
  footer .info-footer .altus-info {
    order: 1;
  }
  footer .info-footer .lsqa-info {
    text-align: center;
    justify-content: center;
    order: 2;
  }
  footer .info-footer .sitemap {
    text-align: center;
    justify-content: center;
    order: 3;
  }
}
